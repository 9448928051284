import React, { useEffect } from 'react';
import './BouncingText.css';

const BouncingText: React.FC = () => {
    useEffect(() => {
        const textElement = document.getElementById('bouncing-text');

        if (textElement) {
            textElement.animate(
                [
                    { transform: 'translate(0, 0)' },
                    { transform: `translate(${getRandomValue()}, ${getRandomValue()})` },
                    { transform: `translate(${getRandomValue()}, ${getRandomValue()})` },
                    { transform: 'translate(0, 0)' },
                ],
                {
                    duration: 5000, // 5 seconds for one iteration
                    iterations: Infinity, // Infinite loop
                }
            );
        }
    }, []);

    const getRandomValue = () => {
        const randomValue = Math.random() * 100 - 50; // Generates a random value between -50 and 50
        return `${randomValue}vw`;
    };

    return (
        <div id="bouncing-text" className="bouncing-text">
          Kerst met de fam!
        </div>
    );
};

export default BouncingText;
