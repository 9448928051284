import React from 'react';
import './App.css';
import BouncingText from './BouncingText';
import Snowfall from './Snowfall';


function App() {
    return (
        <div>
            <Snowfall/>
            <audio
                src="jingle-bell-rock.ogg"
                autoPlay
                loop
            />
            <BouncingText/>
        </div>
    );
}

export default App;
