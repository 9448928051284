import React, { useEffect } from 'react';
import './Snowfall.css';

const Snowfall: React.FC = () => {
    useEffect(() => {
        const snowContainer = document.getElementById('snow-container');
        const numSnowflakes = 50;

        for (let i = 0; i < numSnowflakes; i++) {
            const snowflake = document.createElement('div');
            snowflake.className = 'snowflake';

            // Randomize initial position
            const initialX = Math.random() * window.innerWidth;
            const initialY = Math.random() * window.innerHeight;
            snowflake.style.left = `${initialX}px`;
            snowflake.style.top = `${initialY}px`;

            snowContainer?.appendChild(snowflake);

            const animationDuration = Math.random() * 4 + 2; // between 2 and 6 seconds
            snowflake.style.animationDuration = `${animationDuration}s`;
        }
    }, []);

    return <div id="snow-container" className="snowfall-container"></div>;
};

export default Snowfall;
